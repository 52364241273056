



















































































































































































































































































































































































































































































































































































































































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import Message from '@/components/ui/Message.vue'
import CustomLink from '@/components/g/CustomLink.vue'
import auth from '@/composables/auth'
import { authorizationTypes } from '@/inc/types'
import PointContact from '@/components/commune/components/PointContact.vue'

const { VUE_APP_DOMAIN } = process.env

export default defineComponent({
  name: 'my-commune-dashboard',
  components: {
    Message,
    CustomLink,
    PointContact,
  },
  setup(_props, ctx) {
    const message = ref(true)
    const { i18n } = useState('commune', ['i18n'])
    const { authorizations, getCodesPostaux } = useGetters('user', [
      'authorizations',
      'getCodesPostaux',
    ])
    const {
      getNbrPannesEpEnCours,
      getNbrPannesElecEnCours,
      getNbrPannesElecPlan,
    } = useGetters('commune', [
      'getNbrPannesEpEnCours',
      'getNbrPannesElecEnCours',
      'getNbrPannesElecPlan',
    ])

    const unauthorized = ref(ctx.root.$route.query.access === 'unauthorized')
    const mutations = {
      ...useMutations('my', ['START_LOADING', 'STOP_LOADING']),
    }
    const { currentLang } = useGetters(['currentLang'])
    const [cp] = getCodesPostaux.value
    let pannesElecPath = 'en-temps-reel/pannes-et-interruptions/'
    if (currentLang.value === 'de') {
      pannesElecPath = 'in-echtzeit/stromausfalle/'
    }
    const pannesElecUrl = ref(
      `${VUE_APP_DOMAIN}/${currentLang.value}/${pannesElecPath}?cp=${cp}`
    )
    let pannesEPpath = 'en-temps-reel/panne-eclairage-public/'
    if (currentLang.value === 'de') {
      pannesEPpath = 'in-echtzeit/storung-offentliche-beleuchtung/'
    }
    const pannesEPUrl = ref(
      `${VUE_APP_DOMAIN}/${currentLang.value}/${pannesEPpath}?cp=${cp}`
    )

    onMounted(() => {
      console.log('Mounted Dashboard')
      mutations.STOP_LOADING()
    })

    const dismissMessage = () => {
      ctx.root.$router.replace({ query: {} })
      unauthorized.value = false
    }

    return {
      message,
      ...useState('user', ['firstname', 'lastname', 'commune']),
      i18n,
      isLogged: auth.isLogged,
      unauthorized,
      dismissMessage,
      authorizations,
      authorizationTypes,
      getNbrPannesEpEnCours,
      getNbrPannesElecEnCours,
      getNbrPannesElecPlan,
      pannesElecUrl,
      pannesEPUrl,
      ...useState('commune', ['isElec', 'isEp', 'isGaz']),
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
