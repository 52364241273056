var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n && _vm.i18n.communeDashboard && _vm.isLogged)?_c('div',{staticClass:"my-dashboard wrapper commune pt-m pb-l"},[(_vm.unauthorized)?_c('div',{staticClass:"alert-message"},[_vm._v(" "+_vm._s(_vm.i18n.messages.accesNonAutorise)+" "),_c('span',{staticClass:"close-link",on:{"click":function($event){return _vm.dismissMessage()}}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 20 20","symbol":"ui-cross"}})])]):_vm._e(),_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"page-header__title"},[_c('h1',{staticClass:"main-title"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.welcome)),_c('br'),_c('span',{staticClass:"username outline"},[_vm._v(_vm._s(_vm.firstname)+" "+_vm._s(_vm.lastname))])]),_c('p',{staticClass:"page-header__text welcome-text"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.subtitle)+" "+_vm._s(_vm.commune.localite)),_c('br')])]),_c('div',{staticClass:"page-header__actions"},[_c('PointContact')],1)]),(_vm.i18n.communeDashboard.optionHtml.displayContent.length > 0)?_c('div',{staticClass:"mt-s",domProps:{"innerHTML":_vm._s(_vm.i18n.communeDashboard.optionHtml.htmltext)}}):_vm._e(),_c('div',{staticClass:"row row--mobile mt-s"},[(
        _vm.authorizations.includes(_vm.authorizationTypes.INDEX_CONSULTER) ||
        _vm.authorizations.includes(_vm.authorizationTypes.ALL)
      )?_c('router-link',{class:'col block mb-xs block--mobile',attrs:{"to":{ name: 'CommuneIndex' }}},[_c('div',{staticClass:"block__header"},[_c('div',{staticClass:"block__header__img"},[(_vm.i18n.communeDashboard.relevesIndex.image)?_c('img',{attrs:{"src":_vm.apiDomain + _vm.i18n.communeDashboard.relevesIndex.image.url,"alt":""}}):_c('img',{attrs:{"alt":"","src":require("../../assets/svg/commune/index-mobile.svg")}})]),_c('h2',{staticClass:"block__header__title h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.relevesIndex.title)+" ")])])]):_vm._e(),(
        _vm.isEp &&
        (_vm.authorizations.includes(_vm.authorizationTypes.CONSOMMATIONS_EP) ||
          _vm.authorizations.includes(_vm.authorizationTypes.ALL))
      )?_c('router-link',{class:'col block mb-xs block--mobile',attrs:{"to":{ name: 'CommuneConsommationEp' }}},[_c('div',{staticClass:"block__header"},[_c('div',{staticClass:"block__header__img"},[(_vm.i18n.communeDashboard.consoEp.image)?_c('img',{attrs:{"src":_vm.apiDomain + _vm.i18n.communeDashboard.consoEp.image.url,"alt":""}}):_c('img',{attrs:{"alt":"","src":require("../../assets/svg/commune/consommation-ep-mobile.svg")}})]),_c('h2',{staticClass:"block__header__title h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.consoEp.title)+" ")])])]):_vm._e(),(
        _vm.authorizations.includes(_vm.authorizationTypes.CONSOMMATIONS) ||
        _vm.authorizations.includes(_vm.authorizationTypes.ALL)
      )?_c('router-link',{class:'col block mb-xs block--mobile',attrs:{"to":{ name: 'CommuneConsoSmart' }}},[_c('div',{staticClass:"block__header"},[_c('div',{staticClass:"block__header__img"},[(_vm.i18n.communeDashboard.conso.image)?_c('img',{attrs:{"src":_vm.apiDomain + _vm.i18n.communeDashboard.conso.image.url,"alt":""}}):_c('img',{attrs:{"alt":"","src":require("../../assets/svg/commune/consommation-mobile.svg")}})]),_c('h2',{staticClass:"block__header__title h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.conso.title)+" ")])])]):_vm._e(),(_vm.isEp)?_c('a',{class:'col block mb-xs block--mobile',attrs:{"href":_vm.pannesEPUrl,"rel":"noopener noreferrer","target":"_blank"}},[_c('div',{staticClass:"block__header"},[_c('div',{staticClass:"block__header__img"},[(_vm.i18n.communeDashboard.pannesEp.image)?_c('img',{attrs:{"src":_vm.apiDomain + _vm.i18n.communeDashboard.pannesEp.image.url,"alt":""}}):_c('img',{attrs:{"alt":"","src":require("../../assets/svg/commune/pannes-ep-mobile.svg")}})]),_c('h2',{staticClass:"block__header__title h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.pannesEp.title)+" ")])])]):_vm._e(),(
        _vm.authorizations.includes(_vm.authorizationTypes.RACCORDEMENTS) ||
        _vm.authorizations.includes(_vm.authorizationTypes.ALL)
      )?_c('router-link',{class:'col block mb-xs block--mobile',attrs:{"to":{ name: 'CommuneRecord' }}},[_c('div',{staticClass:"block__header"},[_c('div',{staticClass:"block__header__img"},[(_vm.i18n.communeDashboard.raccordement.image)?_c('img',{attrs:{"src":_vm.apiDomain + _vm.i18n.communeDashboard.raccordement.image.url,"alt":""}}):_c('img',{attrs:{"alt":"","src":require("../../assets/svg/commune/raccordement-mobile.svg")}})]),_c('h2',{staticClass:"block__header__title h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.raccordement.title)+" ")])])]):_vm._e(),(_vm.isElec)?_c('a',{class:'col block mb-xs block--mobile',attrs:{"href":_vm.pannesElecUrl,"rel":"noopener noreferrer","target":"_blank"}},[_c('div',{staticClass:"block__header block_header"},[_c('div',{staticClass:"block__header__img"},[(_vm.i18n.communeDashboard.pannesElec.image)?_c('img',{attrs:{"src":_vm.apiDomain + _vm.i18n.communeDashboard.pannesElec.image.url,"alt":""}}):_c('img',{attrs:{"alt":"","src":require("../../assets/svg/commune/pannes-el-mobile.svg")}})]),_c('h2',{staticClass:"block__header__title h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.pannesElec.title)+" ")])])]):_vm._e()],1),_c('div',{staticClass:"row row--desktop mt-s"},[(
        _vm.authorizations.includes(_vm.authorizationTypes.INDEX_CONSULTER) ||
        _vm.authorizations.includes(_vm.authorizationTypes.ALL)
      )?_c('article',{staticClass:"col block block--desktop mb-s"},[_c('div',{staticClass:"block__header"},[_c('div',{staticClass:"block__header__img"},[(_vm.i18n.communeDashboard.relevesIndex.image)?_c('img',{attrs:{"src":_vm.apiDomain + _vm.i18n.communeDashboard.relevesIndex.image.url,"alt":"","height":"106","width":"105"}}):_c('img',{attrs:{"alt":"","height":"106","src":require("../../assets/svg/commune/index.svg"),"width":"105"}})]),_c('h2',{staticClass:"block__header__title h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.relevesIndex.title)+" ")])]),_c('div',{staticClass:"block__body"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.relevesIndex.text)+" ")]),_c('div',{staticClass:"block__footer"},[_c('CustomLink',{staticClass:"block__footer__btn",attrs:{"content":{
            label: _vm.i18n.communeDashboard.relevesIndex.labelButton,
            to: { name: 'CommuneIndex' },
          },"modifiers":['btn', 'green', 'white', 'big', 'rectrounded', 'full']}})],1)]):_vm._e(),(
        _vm.isEp &&
        (_vm.authorizations.includes(_vm.authorizationTypes.CONSOMMATIONS_EP) ||
          _vm.authorizations.includes(_vm.authorizationTypes.ALL))
      )?_c('article',{staticClass:"col block block--desktop mb-s"},[_c('div',{staticClass:"block__header"},[_c('div',{staticClass:"block__header__img"},[(_vm.i18n.communeDashboard.consoEp.image)?_c('img',{attrs:{"src":_vm.apiDomain + _vm.i18n.communeDashboard.consoEp.image.url,"alt":"","height":"101","width":"102"}}):_c('img',{attrs:{"alt":"","height":"101","src":require("../../assets/svg/commune/consommations-ep.svg"),"width":"102"}})]),_c('h2',{staticClass:"block__header__title h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.consoEp.title)+" ")])]),_c('div',{staticClass:"block__body"},[_vm._v(_vm._s(_vm.i18n.communeDashboard.consoEp.text))]),_c('div',{staticClass:"block__footer"},[_c('CustomLink',{staticClass:"block__footer__btn",attrs:{"content":{
            label: _vm.i18n.communeDashboard.consoEp.labelButton,
            to: { name: 'CommuneConsommationEp' },
          },"modifiers":['btn', 'green', 'white', 'big', 'rectrounded', 'full']}})],1)]):_vm._e(),(_vm.isEp)?_c('article',{staticClass:"col block block--desktop mb-s"},[_c('div',{staticClass:"block__header"},[_c('div',{staticClass:"block__header__img"},[(_vm.i18n.communeDashboard.pannesEp.image)?_c('img',{attrs:{"src":_vm.apiDomain + _vm.i18n.communeDashboard.pannesEp.image.url,"alt":"","height":"101","width":"101"}}):_c('img',{attrs:{"alt":"","height":"101","src":require("../../assets/svg/commune/pannes-ep.svg"),"width":"101"}})]),_c('h2',{staticClass:"block__header__title h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.pannesEp.title)+" ")])]),(_vm.getNbrPannesEpEnCours)?_c('div',{staticClass:"block__body block__body--status"},[_c('span',{staticClass:"block__body__header"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.pannesEp.text)+" ")]),(_vm.getNbrPannesEpEnCours)?_c('p',[_c('span',{staticClass:"badge-rounded"},[_vm._v(_vm._s(_vm.getNbrPannesEpEnCours))]),_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.pannesEp.epEnCours)+" ")]):_vm._e()]):_c('div',{staticClass:"block__body"},[_c('g-loader')],1),_c('div',{staticClass:"block__footer"},[_c('CustomLink',{staticClass:"block__footer__btn",attrs:{"content":{
            label: _vm.i18n.communeDashboard.pannesEp.labelButton,
            url: _vm.pannesEPUrl,
            target: '_blank',
          },"modifiers":['btn', 'green', 'white', 'big', 'rectrounded', 'full'],"type":'externalLink'}})],1)]):_vm._e(),(
        _vm.authorizations.includes(_vm.authorizationTypes.CONSOMMATIONS) ||
        _vm.authorizations.includes(_vm.authorizationTypes.ALL)
      )?_c('article',{staticClass:"col block block--desktop mb-s"},[_c('div',{staticClass:"block__header"},[_c('div',{staticClass:"block__header__img"},[(_vm.i18n.communeDashboard.conso.image)?_c('img',{attrs:{"src":_vm.apiDomain + _vm.i18n.communeDashboard.conso.image.url,"alt":"","height":"107","width":"106"}}):_c('img',{attrs:{"alt":"","height":"107","src":require("../../assets/svg/commune/consommations.svg"),"width":"106"}})]),_c('h2',{staticClass:"block__header__title h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.conso.title)+" ")])]),_c('div',{staticClass:"block__body"},[_vm._v(_vm._s(_vm.i18n.communeDashboard.conso.text))]),_c('div',{staticClass:"block__footer"},[_c('CustomLink',{staticClass:"block__footer__btn",attrs:{"content":{
            label: _vm.i18n.communeDashboard.conso.labelButton,
            to: { name: 'CommuneConsoSmart' },
          },"modifiers":['btn', 'green', 'white', 'big', 'rectrounded', 'full']}})],1)]):_vm._e(),(
        _vm.authorizations.includes(_vm.authorizationTypes.RACCORDEMENTS) ||
        _vm.authorizations.includes(_vm.authorizationTypes.ALL)
      )?_c('article',{staticClass:"col block block--desktop mb-s"},[_c('div',{staticClass:"block__header"},[_c('div',{staticClass:"block__header__img"},[(_vm.i18n.communeDashboard.raccordement.image)?_c('img',{attrs:{"src":_vm.apiDomain + _vm.i18n.communeDashboard.raccordement.image.url,"alt":"","height":"106","width":"105"}}):_c('img',{attrs:{"alt":"","height":"106","src":require("../../assets/svg/commune/raccordement.svg"),"width":"105"}})]),_c('h2',{staticClass:"block__header__title h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.raccordement.title)+" ")])]),_c('div',{staticClass:"block__body"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.raccordement.text)+" ")]),_c('div',{staticClass:"block__footer"},[_c('CustomLink',{staticClass:"block__footer__btn",attrs:{"content":{
            label: _vm.i18n.communeDashboard.raccordement.labelButton,
            to: { name: 'CommuneRecord' },
          },"modifiers":['btn', 'green', 'white', 'big', 'rectrounded', 'full']}})],1)]):_vm._e(),(_vm.isElec)?_c('article',{staticClass:"col block block--desktop mb-s"},[_c('div',{staticClass:"block__header block_header"},[_c('div',{staticClass:"block__header__img"},[(_vm.i18n.communeDashboard.pannesElec.image)?_c('img',{attrs:{"src":_vm.apiDomain + _vm.i18n.communeDashboard.pannesElec.image.url,"alt":"","height":"101","width":"102"}}):_c('img',{attrs:{"alt":"","height":"101","src":require("../../assets/svg/commune/pannes-el.svg"),"width":"102"}})]),_c('h2',{staticClass:"block__header__title h3"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.pannesElec.title)+" ")])]),(_vm.getNbrPannesElecEnCours || _vm.getNbrPannesElecPlan)?_c('div',{staticClass:"block__body"},[_c('span',{staticClass:"block__body__header"},[_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.pannesElec.text)+" ")]),(_vm.getNbrPannesElecEnCours)?_c('p',[_c('span',{staticClass:"badge-rounded"},[_vm._v(_vm._s(_vm.getNbrPannesElecEnCours))]),_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.pannesElec.textPannesElecEnCours)+" ")]):_vm._e(),(_vm.getNbrPannesElecPlan)?_c('p',[_c('span',{staticClass:"badge-rounded"},[_vm._v(_vm._s(_vm.getNbrPannesElecPlan))]),_vm._v(" "+_vm._s(_vm.i18n.communeDashboard.pannesElec.textPannesElecPlan)+" ")]):_vm._e()]):_c('div',{staticClass:"block__body"},[_c('g-loader')],1),_c('div',{staticClass:"block__footer"},[_c('CustomLink',{staticClass:"block__footer__btn",attrs:{"content":{
            label: _vm.i18n.communeDashboard.pannesElec.labelButton,
            url: _vm.pannesElecUrl,
            target: '_blank',
          },"modifiers":['btn', 'green', 'white', 'big', 'rectrounded', 'full'],"type":'externalLink'}})],1)]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }